import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"transition":"dialog-bottom-transition","width":"600px"},on:{"click:outside":function($event){return _vm.close()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Firmar documento")]),_c(VCardText,[_c('label',{staticClass:"fs-16 mt-4 mb-3"},[_vm._v("Seleccione el numero de foja con la cual desea firmar el documento")]),_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-md-6 mt-5"},[(!_vm.isCertificacionFirma())?_c('DatePickerEsp',{attrs:{"label":"Fecha","initToday":true,"date":_vm.seal.fecha,"name":"fecha"},on:{"setDate":function (date) {
                  _vm.seal.fecha = date;
                }}}):_vm._e(),_c(VCombobox,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"dense":"","data-vv-as":"foja","name":"foja","items":_vm.getFojasComboBox(),"label":"Nº Foja*","autocomplete":"off"},model:{value:(_vm.seal.foja),callback:function ($$v) {_vm.$set(_vm.seal, "foja", $$v)},expression:"seal.foja"}}),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.errors.first("foja")))])],1)])]),_c(VCardActions,[_c(VSpacer),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cerrar")]),_c(VBtn,{attrs:{"color":"green","text":"","loading":_vm.loading,"disabled":_vm.seal.foja == null},on:{"click":function($event){return _vm.$emit('openModalPdf', _vm.seal, _vm.document)}}},[_vm._v("Firmar")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }