import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close()}},model:{value:(_vm.dialog_history),callback:function ($$v) {_vm.dialog_history=$$v},expression:"dialog_history"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Historial de Estados "+_vm._s(_vm.task_name)+" ")])]),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.history,"item-key":"id"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.datetime))]),_c('td',{staticStyle:{"min-width":"135px"},domProps:{"innerHTML":_vm._s(props.item.state.name)}}),_c('td',[_vm._v(" "+_vm._s(props.item.user != null ? props.item.user.username : "-")+" ")])])]}}])})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cerrar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }