import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"500"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('cancel')},"click:outside":function($event){return _vm.$emit('cancel')}},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))]),_c(VCardText,[_c('p',{staticClass:"mt-5 mb-3 fs-16 text-center",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c(VCheckbox,{staticStyle:{"font-size":"14px"},attrs:{"label":_vm.checkbox_label},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" No ")]),_c(VBtn,{attrs:{"color":"green darken-1","text":"","loading":_vm.loading},on:{"click":_vm.acceptMethod}},[_vm._v(" Si ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }