import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"900"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.getTitle()))]),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.list,"options":{
            descending: true,
            itemsPerPage: 15,
          },"footer-props":{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.voucher_asoc.creation_date))]),_c('td',[_vm._v(_vm._s(props.item.voucher_asoc.cbte_tipo_name))]),_c('td',[_vm._v(_vm._s((props.item.voucher_asoc.voucher_number != null)?props.item.voucher_asoc.voucher_number.toString().padStart(8,"0"):'-'))]),_c('td',[_vm._v(_vm._s(props.item.voucher_asoc.emitter.name))]),_c('td',[_vm._v(_vm._s(props.item.voucher_asoc.receiver.name))]),_c('td',[_vm._v(_vm._s(props.item.voucher_asoc.mon_name))]),_c('td',[_vm._v(_vm._s(_vm.formatUtils.formatPrice(props.item.voucher_asoc.imp_total)))])])]}},{key:"no-results",fn:function(){return [_c(VAlert,{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s("Busqueda sin resultados - " + _vm.search)+" ")])]},proxy:true}])})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }