import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},on:{"click:outside":function($event){return _vm.$emit('closeDialog')},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('closeDialog')},"input":function($event){return _vm.$emit('closeDialog', false)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Estado de la tarea")]),_c(VCardText,[_c('label',{staticClass:"fs-16 mt-3"},[_vm._v("Seleccione los firmates que desea agregar a la tarea")]),_c(VCombobox,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"mt-4",attrs:{"data-vv-as":"state","name":"state","item-text":"name","item-value":"id","items":_vm.states,"label":"Estado","autocomplete":"off"},model:{value:(_vm.selected_state),callback:function ($$v) {_vm.selected_state=$$v},expression:"selected_state"}}),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.errors.first("state")))])],1),_c(VCardActions,[_c(VSpacer),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"success","text":"","loading":_vm.loading},on:{"click":_vm.acceptModal}},[_vm._v("Aceptar")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }