import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.dialog_delete),callback:function ($$v) {_vm.dialog_delete=$$v},expression:"dialog_delete"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Confirmar")]),_c(VCardText,[_c('p',{staticClass:"mt-5 mb-0 fs-16 text-center"},[_vm._v(" ¿Esta seguro que quiere eliminar este elemento? ")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.$emit('updateDialogDelete', false)}}},[_vm._v(" No ")]),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.$emit('deleteItemConfirm')}}},[_vm._v(" Si ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }