import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c(VToolbar,{staticClass:"pl-5 elevation-0",attrs:{"color":"#fc6803","dark":"","dense":"","app":""}},[_c(VTooltip,{attrs:{"bottom":"","open-delay":1000},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAppBarNavIcon,_vm._g(_vm._b({on:{"click":_vm.handleSidebar}},'v-app-bar-nav-icon',attrs,false),on))]}}])},[_c('span',[_vm._v("Menú principal")])]),_c('div',{staticClass:"position-absolute d-flex align-items-center",staticStyle:{"right":"5px"}},[_c('p',{staticClass:"mb-0 mr-2"},[_vm._v(_vm._s(_vm.getUsername()))]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openLogoutModal()}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-export")])],1)]}}])},[_c('span',[_vm._v("Cerrar sesión")])])],1)],1)],1),_c('DialogYesNo',{attrs:{"open":_vm.dialog_logout,"title":"Confirmar","message":"¿Estas seguro que quieres cerrar sesion?"},on:{"cancel":function($event){_vm.dialog_logout = false},"accept":_vm.logoutConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }